.login-container {
    height: 100vh;
  }
  
  .login-image {
    background: url('../../../images/background.png') no-repeat center center;
    background-size: cover;
  }
  
  .login-card {
    width: 100%;
    max-width: 400px;
    padding: 20px;
  }
  
  .alert-position {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1050;
    width: 300px;
  }
  